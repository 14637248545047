<template>
  <v-row>
    <v-col lg="6" md="6" sm="6" v-for="(bloque, index) in bloques" :key="index" class="py-0 mb-7 col-12">
      <v-card>
        <v-card-text class="pa-1">
          <div class="d-flex align-center">
            <v-btn color="primary" class="elevation-0 mx-2" fab dark>
              <v-icon>fas fa-bed</v-icon>
            </v-btn>
            <div class="mx-2 col-10">
              <h3 class="align-center body-1 font-weight-regular">{{ bloque.bloque }}</h3>
              <v-row>
                <v-col lg="4" md="4" sm="6" class="col-12">
                  <h4>{{ 'T: ' + bloque.total + ' - 100%' }}</h4>
                  <div class="d-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" x-small color="green">fas fa-circle</v-icon>
                      </template>
                      Habilitados
                    </v-tooltip>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.habilitado + ' - ' + bloque.phabilitado }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" x-small color="red">fas fa-circle</v-icon>
                      </template>
                      Deshabilitados
                    </v-tooltip>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.deshabilitado + ' - ' + bloque.pdeshabilitado }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" x-small color="#FFAB00">fas fa-circle</v-icon>
                      </template>
                      Ocupados
                    </v-tooltip>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.ocupado + ' - ' + bloque.pocupado }}
                    </h4>
                  </div>
                </v-col>
                <v-col lg="4" md="4" sm="6" class="col-12">
                  <h4>
                    {{ 'F: ' + bloque.femenino + ' - ' + bloque.pfemenino }}
                  </h4>
                  <div class="d-flex">
                    <v-icon small color="green">fas fa-female</v-icon>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.fhabilitado + ' - ' + bloque.pfhabilitado }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <v-icon small color="red">fas fa-female</v-icon>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.fdeshabilitado + ' - ' + bloque.pfdeshabilitado }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <v-icon small color="#FFAB00">fas fa-female</v-icon>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.focupado + ' - ' + bloque.pfocupado }}
                    </h4>
                  </div>
                </v-col>
                <v-col lg="4" md="4" sm="6" class="col-12">
                  <h4>
                    {{ 'M: ' + bloque.masculino + ' - ' + bloque.pmasculino }}
                  </h4>
                  <div class="d-flex">
                    <v-icon small color="green">fas fa-male</v-icon>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.mhabilitado + ' - ' + bloque.pmhabilitado }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <v-icon small color="red">fas fa-male</v-icon>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.mdeshabilitado + ' - ' + bloque.pmdeshabilitado }}
                    </h4>
                  </div>
                  <div class="d-flex">
                    <v-icon small color="#FFAB00">fas fa-male</v-icon>
                    <h4 class="mx-2 align-center font-weight-light">
                      {{ bloque.mocupado + ' - ' + bloque.pmocupado }}
                    </h4>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    bloques: {
      type: Array
    }
  },
  data: () => ({})
};
</script>
